import React, { useContext, useEffect, useState } from 'react';
import { multiStepContext } from '../StepContext';
import serviceProcessBar from '../assets/service-process-bar.png';
import simCard from '../assets/sim_card.png'
import phone from '../assets/phone_iphone.png'
import '../Styles/ServicePage.css'

const ServicePage = () => {
  const { setStep, userData, setUserData } = useContext(multiStepContext);
  const [showPopup, setShowPopup] = useState(false);
  const [popupOption, setPopupOption] = useState('');

  useEffect(() => {
    if (!userData.phoneNumberOption) {
      setUserData({ ...userData, phoneNumberOption: "ไม่ใช่" });
    }
  }, [setUserData, userData]);

  const handleCardSelect = (value) => {
    if (value === 'ไม่ใช่') {
      setShowPopup(true);
    } else {
      setUserData({ ...userData, phoneNumberOption: value });
      setStep(5);
    }
  };

  const handlePopupSubmit = () => {
    if (popupOption === 'option1') {
      setUserData({ ...userData, phoneNumberOption: 'ไม่ใช่' });
      setStep(5);
    } else if (popupOption === 'option2') {
      setStep(29);
    }
    setShowPopup(false);
  };

  return (
    <div className="service-container">
      <div className="service-process-bar">
        <img src={serviceProcessBar} className="service-pro-bar" alt="Service Process Bar" />
      </div>
      <div className="service-info">
        โปรดกรอกข้อมูลลงในแบบฟอร์ม
        <br />
        <span style={{ fontSize: '16' }}>Please fill out  the form with your information</span>
      </div>
      <div className="service-choice">
        ท่านประสงค์ที่จะ
        <div className="card-container">
          <div
            className={`service-card ${userData.phoneNumberOption === 'ใช่' ? 'selected' : ''}`}
            onClick={() => handleCardSelect('ใช่')}
          >
            <img src={simCard} className='service-img'></img>
            <p>รับซิมการ์ดใหม่เบอร์ใหม่</p>
          </div>
          <div
            className={`service-card ${userData.phoneNumberOption === 'ไม่ใช่' ? 'selected' : ''}`}
            onClick={() => handleCardSelect('ไม่ใช่')}
          >
            <img src={phone} className='service-img'></img>
            <p>ใช้หมายเลขโทรศัพท์เดิม</p>
          </div>
        </div>
      </div>
      <div className="form-bt">
        <button onClick={() => setStep(4)} className="form-back-bt">ย้อนกลับ</button>
      </div>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-window">
            <div className="popup-header">
              <span className="popup-title">หมายเลขโทรศัพท์เดิม</span>
            </div>
            <div className="popup-content">
              <div className="popup-select">
                <input
                  type="checkbox"
                  id="option1"
                  value="option1"
                  checked={popupOption === 'option1'}
                  onChange={() => setPopupOption('option1')}
                />
                <label htmlFor="option1">ท่านใช้เครือข่าย Infinite โครงข่ายโทรคมนาคมแห่งชาติ</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="option2"
                  value="option2"
                  checked={popupOption === 'option2'}
                  onChange={() => setPopupOption('option2')}
                />
                <label htmlFor="option2">ท่านใช้งานเครือข่ายอื่นและมีความประสงค์เลือกใช้เบอร์เดิม</label>
              </div>
              <div className="popup-buttons">
                <button className="popup-cancel" onClick={() => {
                  setShowPopup(false)
                  setPopupOption('')
                }}>
                  ยกเลิก
                </button>
                <button className="popup-accept" onClick={handlePopupSubmit}>
                  ตกลง
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ServicePage