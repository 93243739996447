import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/Authentication.css'
import line from "../assets/line.png";
import Axios from 'axios';

const Authentication = () => {
  const [inputValue, setInputValue] = useState('');
  const { setStep, userData, setUserData } = useContext(multiStepContext);
  const [showInfoPopup, setShowInfoPopup] = useState(true);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [agreeToPopupTerms, setAgreeToPopupTerms] = useState(false);

  const apiURL = process.env.REACT_APP_API_URL;

   const [data, setData] = useState("");
  
  const checkNumber = async () => {

    if(inputValue.length !== 13){
      alert('กรุณากรอกหมายเลขบัตรประชาชนให้ครบ 13 หลัก')
    }else{

      const response = await Axios.get(apiURL+`checkStudentNationalID/${inputValue}`);
      if (response.data.result === "Found") {
        setUserData({ ...userData, citizenId: inputValue }); 
        setStep(3);
      } 
      else if (response.data.result === "nameExisted"){
        setStep(21);
      }
      else if (response.data.result === "FoundNotInTime"){
        setStep(22);
      }
      else if (response.data.result === "FoundMaxRegis"){
        setStep(24);
      }
      else {
        setStep(17);
      }
    }
  };

  const [agreeTerms, setAgreeTerms] = useState(false);

    const handleCheckboxChange = () => {
    if (!agreeTerms) {
      setShowTermsPopup(true);
    } else {
      setAgreeTerms(false);
    }
  };

  const handlePopupAccept = () => {
    if (agreeToPopupTerms) {
      setAgreeTerms(true);
      setShowTermsPopup(false);
      setAgreeToPopupTerms(false)
    } else {
      alert("กรุณายอมรับเงื่อนไขก่อนดำเนินการต่อ");
    }
  };

  const handlePopupCancel = () => {
    setAgreeTerms(false);
    setShowTermsPopup(false);
    setAgreeToPopupTerms(false)
  };

  const handlePopupCheckboxChange = () => {
    setAgreeToPopupTerms(!agreeToPopupTerms);
  };

  const closeInfoPopup = () => {
    setShowInfoPopup(false);
  };

    const handleInputChange = (e) => {
      let numericValue = e.target.value.replace(/\D/g, '').trim();
      if (numericValue.length > 13) {
          numericValue = numericValue.slice(0, 13);
      }
      setInputValue(numericValue);
  }
  

  return (
    <div className="authen-container">
      {showInfoPopup && (
        <div className="popup-overlay">
          <div className="popup-window">
            <div className="popup-header">
              <span className="popup-title">ขั้นตอนการลงทะเบียน</span>
              <button className="popup-close-btn" onClick={closeInfoPopup}>
                &times;
              </button>
            </div>
            <div className="popup-content">
              <ul className="popup-list">
                <li>เตรียมบัตรประชาชาน ของท่าน</li>
                <img src={line} className="line-between-point" alt="line" />
                <li>เตรียมข้อมูลผู้ปกครอง / ผู้ดูแลตามกฎหมายของท่าน</li>
                <img src={line} className="line-between-point" alt="line" />
                <li>
                  หากท่านต้องการใช้หมายเลขเบอร์โทรเดิมของท่านต่อ
                  กรุณาทำการย้ายเครือข่ายไปยังเครือข่าย Infinite โครงข่ายโทรคมนาคมแห่งชาติ ก่อนการลงทะเบียน
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

{showTermsPopup && (
        <div className="popup-overlay">
          <div className="popup-window">
            <div className="popup-header">
              <span className="popup-title">ข้อกำหนดและเงื่อนไขการให้บริการ</span>
            </div>
            <div className="popup-content-term">
              <div className="popup-term-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum....</div>
              <label className="popup-term-checkbox">
                <input
                  type="checkbox"
                  checked={agreeToPopupTerms}
                  onChange={handlePopupCheckboxChange}
                />{" "}
                ข้าพเจ้ายอมรับข้อกำหนด และเงื่อนไขการให้บริการข้างต้น
              </label>
              <div className="popup-buttons">
                <button className="popup-cancel" onClick={handlePopupCancel}>
                  ยกเลิก
                </button>
                <button className="popup-accept" onClick={handlePopupAccept}>
                  ตกลง
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

        <div className="authen-info">
              ตรวจสอบสิทธิของท่าน
              <br/>
              <span style={{ fontSize: '16' }}>Check your eligibility</span> 
        </div>
        <div className='authen-input'>
          <div className="authen-id">
            <div className="authen-id-info">
            หมายเลขบัตรประจําตัวประชาชน
            </div>
            <input
                className="id-text"
                type='text'
                placeholder='โปรดกรอกข้อมูล'
                value={inputValue}
                onChange={handleInputChange}
              />
          </div>
        </div>
        <div className='authen-next-bt'>
            <label className="policy-con">
                <input type="checkbox" checked={agreeTerms} onChange={handleCheckboxChange}/> 
                <div className='policy-info'>
                    ข้าพเจ้ายินยอมให้ใช้ข้อมูลส่วนตัวสําหรับการตรวจสอบการได้รับสิทธิเข้าร่วมโครงการ<br/>
                    <span className="policy">
                        I hereby consent to my personal data being processed for the purpose of considering my eligibility
                    </span>
                </div>
            </label>
            <div className="bt-position">
              <button className="authen-back-bt" onClick={() => setStep(1)}>ย้อนกลับ</button>
              <button onClick={checkNumber} disabled={!agreeTerms} className="authen-next-button">
                  ต่อไป
              </button>
            </div>
        </div>
    </div>
  );
};

export default Authentication;