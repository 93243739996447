import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/AccuracyPage.css'
import Axios from 'axios';

const AccuracyPage = () => {
    const apiURL = process.env.REACT_APP_API_URL;

    const { setStep, userData, setUserData, submitData } = useContext(multiStepContext);
    const location = userData.locationOption;
    const finalData = useContext(multiStepContext).finalData;
    const branchId = parseInt(userData.branchId);
    const [phoneNum, setPhoneNum] = useState('')
    const [isPopupVisible, setIsPopupVisible] = useState(userData.simMethod === 'branch');
    const [popupDate, setPopupDate] = useState({ startDate: '', endDate: '' });

    const statusId = userData.statusId;

    const [type, setType] = useState("");

    const getPopupDate = async () => {
        try {
            const response = await Axios.get(apiURL + 'campaignReceiveSimStudent');
            const status = response.data.status;
            const branchDelivery = status.find(item => item.deliveryType_id === 1);
            if (branchDelivery) {
                const { startTime, endTime } = branchDelivery;
                setPopupDate({
                    startDate: new Date(startTime).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' }),
                    endDate: new Date(endTime).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' }),
                });
            }
            } catch (error) {
            console.error('Error fetching popup date:', error);
        }
    };

    const updateStatus = async (statusId) => {
        if (userData.simMethod === 'mail') {
            await Axios.put(apiURL + `changeStudentStatusAddress/${statusId}`, {
                addressName: userData.addressNameNew,
                subDistrict: userData.subDistrictNew,
                district: userData.districtNew,
                province: userData.provinceNew,
                zipCode: userData.zipCodeNew,
                email: userData.emailNew,
                lineId: userData.lineIdNew,
                phoneNumber: userData.phoneNumberNew
            });

        } else {
            const response = await Axios.put(apiURL + `changeStudentStatus/${statusId}`, {
                branchId: branchId
            });
        }
    }

    const getServiceAndNum = async (statusId) => {
        const response = await Axios.get(`${apiURL}getStudentOperationRecord/${statusId}`);
        setType(response.data);
    }

    const serviceDetail = type && type.OpInfo.studentInfo[0].operators.company_name;
    const numberDetail = type && type.OpInfo.studentInfo[0].phoneNumber;
    const purposeNumber = type && type.purpose
    const packageType = type && type.OpInfo.studentInfo[0].packages.packageName

    const accBackStep = () => {
        if (purposeNumber === "ไม่ใช่") {
            setStep(9);
        } else {
            if (userData.simMethod === "mail") {
                setStep(31)
            } else if (userData.simMethod === "branch") {
                setStep(10);
            }
        }
    };

    const renderPurpose = () => {
        if (numberDetail) { return numberDetail; }
        else { return 'รับซิมการ์ดใหม่' }
    };

    const handleLocation = () => {
        if (purposeNumber === "ใช่") {
            if (userData.simMethod === 'mail') {
                const fullAddress = `${userData.addressNameNew || ''} ${userData.subDistrictNew || ''} ${userData.districtNew || ''} ${userData.provinceNew || ''} ${userData.zipCodeNew || ''}`.trim();
                return (
                    <div className='acc-location'>
                        ที่อยู่การจัดส่ง
                        <input type="text" className='status-id' value={fullAddress} readOnly></input>
                    </div>
                );
            } else if (userData.simMethod === "branch") {
                return (
                    <div className='acc-location'>
                        รับซิมการ์ดที่ศูนย์บริการ
                        <input type="text" className='status-id' value={userData.branchName} readOnly></input>
                    </div>
                );
            }
        } else {
            return null;
        }
    };

    useEffect(() => {
        if (purposeNumber === "ใช่" && userData.simMethod === "mail") {
            setPhoneNum(userData.phoneNumberNew);
        }
    }, [purposeNumber, userData]);

    useEffect(() => {
        if (statusId) {
            getServiceAndNum(statusId);
            getPopupDate();
            // updateStatus(statusId);
        }
    }, [statusId]);

    const closeInfoPopup = () => {
        setIsPopupVisible(false);
    };

    return (
        <>
            {
                <div className="acc-body">
                    <div className="acc-container">
                        <div className="acc-area-con">
                            <div className="acc-info">
                                โปรดตรวจสอบความถูกต้อง
                                <br />
                                <span style={{ fontSize: '16' }}>Please check the accuracy</span>
                            </div>
                            <div className="acc-service-detail">
                                <div className="acc-service">
                                    เครือข่าย
                                    <input type="text" className='acc-text' value={serviceDetail} readOnly></input>
                                </div>
                                <div className="acc-phone">
                                    หมายเลขโทรศัพท์
                                    <input type="text" className='acc-text' value={renderPurpose()} readOnly></input>
                                </div>
                            </div>
                            <div className='acc-package'>
                                แพ็คแกจ
                                <input type="text" className='status-id' value={packageType} readOnly></input>
                            </div>
                            {handleLocation()}
                            {userData.simMethod === 'mail' && (
                                <div className='acc-package'>
                                    เบอร์โทรติดต่อในการจัดส่ง
                                    <input type="text" className='status-id' value={phoneNum} readOnly />
                                </div>
                            )}
                            <div className="acc-bt">
                                <button onClick={accBackStep} className="acc-back-bt">ย้อนกลับ</button>
                                <button onClick={() => { updateStatus(statusId); setStep(12); }} className="acc-next-bt">ยืนยันข้อมูล</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isPopupVisible && (
                <div className="popup-overlay">
                    <div className="popup-window">
                        <div className="popup-header">
                            <span className="popup-title">ข้อมูลเพิ่มเติม</span>
                            <button className="popup-close-btn" onClick={closeInfoPopup}>
                                &times;
                            </button>
                        </div>
                        <div className="popup-content">
                            ท่านสามารถเข้ารับซิมการ์ดได้ ตั้งแต่วันที่ {popupDate.startDate} ถึง {popupDate.endDate}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default AccuracyPage;